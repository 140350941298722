import { startCase, camelCase } from "lodash";
import { PricingModel } from "../types";
import type { PropType } from "vue";
import type { ModelType } from "@moirei/complex-pricing/build/types";

export const pricingModelTypes = [
  PricingModel.STANDARD,
  PricingModel.PACKAGE,
  PricingModel.GRADUATED,
  PricingModel.VOLUME,
];

export const pricingModels = pricingModelTypes.map((item) => ({
  title: startCase(camelCase(item)) + " pricing",
  value: item,
}));

export const props = {
  modelValue: { type: String as PropType<ModelType>, required: true },
  readonly: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  density: { type: String as PropType<any> },
};
