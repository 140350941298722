import type { PricingData } from "@moirei/complex-pricing/build/types";
import {
  PricingMeasurementUnit,
  PricingModel,
  type DisplayModelType,
  type PricingModelLike,
} from "./types";
import {
  ChipModelSelect,
  SelectModelSelect,
  TextModelSelect,
} from "./model-select";
import {
  ChipUnitSelect,
  SelectUnitSelect,
  TextUnitSelect,
} from "./unit-select";
import { StandardInput, PackageInput, TiersInput } from "./input";
import { pricingModelTypes } from "./model-select/common";
import { get } from "lodash";

export const getPricingDefaults = (
  modelInput: DisplayModelType,
  measurementDataField = "measurementType"
): PricingData => {
  return {
    model: modelTypeFromInput(modelInput) || PricingModel.STANDARD,
    tiers: [],
    // unit_amount: undefined,
    // units: undefined,
    data: { [measurementDataField]: PricingMeasurementUnit.QUANTITY },
  };
};

export const checkMeasurementType = (
  pricing: PricingData | undefined,
  type: PricingMeasurementUnit,
  measurementDataField = "measurementType"
) => get(pricing?.data, measurementDataField) == type;

export const modelSelectCmp = (type: DisplayModelType) => {
  if (type == "text") {
    return TextModelSelect;
  }
  if (type == "chip") {
    return ChipModelSelect;
  }
  if (type == "select") {
    return SelectModelSelect;
  }
};

export const unitSelectCmp = (type: DisplayModelType) => {
  if (type == "text") {
    return TextUnitSelect;
  }
  if (type == "chip") {
    return ChipUnitSelect;
  }
  if (type == "select") {
    return SelectUnitSelect;
  }
};

export const inputCmp = (
  pricingModel: PricingModelLike,
  pricingInput: DisplayModelType
) => {
  const model = modelFromInputs(pricingModel, pricingInput);

  if (model == PricingModel.PACKAGE) {
    return PackageInput;
  }
  if (isTiersModel(model)) {
    return TiersInput;
  }

  return StandardInput;
};

export const isTiersModel = (pricingModel: PricingModelLike | undefined) => {
  if (!pricingModel) {
    return false;
  }
  const model = normaliseModelType(pricingModel);
  return model == PricingModel.GRADUATED || model == PricingModel.VOLUME;
};

export const modelFromInputs = (
  pricingModel: PricingModelLike,
  pricingInput: DisplayModelType
): PricingModel => {
  return (
    modelTypeFromInput(pricingInput) ||
    normaliseModelType(pricingModel) ||
    PricingModel.STANDARD
  );
};

export const modelTypeFromInput = (
  type: DisplayModelType
): PricingModel | undefined => {
  if (pricingModelTypes.includes(type as any)) {
    return type as PricingModel;
  }
};

export const normaliseModelType = (type: PricingModelLike): PricingModel => {
  return type.toLowerCase() as PricingModel;
};

export const useValues = (model: Ref<PricingData | undefined>) => {
  const unitAmount = ref(0);
  const modelType = ref(PricingModel.STANDARD);

  return {
    unitAmount,
    modelType,
  };
};
