<template>
  <div
    :style="{
      'padding-inline-start': '16px',
      'padding-inline-end': '16px',
      color: 'rgb(var(--v-theme-error))',
      '--v-medium-emphasis-opacity': '1',
      '--v-field-border-opacity': '0.38',
    }"
  >
    <VMessages :active="active" :messages="errors" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { isEmpty } from "lodash";

export default defineComponent({
  props: {
    errors: { type: [String, Array] as PropType<string | string[]> },
  },
  computed: {
    active() {
      return !!this.errors && !isEmpty(this.errors);
    },
  },
});
</script>
