import { VChip, VChipGroup } from "vuetify/components";
import { pricingModels, props } from "./common";

export default defineComponent({
  props,
  render() {
    const chips = pricingModels.map((item) =>
      h(
        VChip,
        {
          value: item.value,
          readonly: this.readonly,
          disabled: this.disabled,
          density: this.density,
        },
        item.title
      )
    );

    return h(
      VChipGroup,
      {
        ...this.$attrs,
        class: "complex-pricing complex-pricing__chips",
        modelValue: this.modelValue,
        density: this.density,
        disabled: this.disabled || this.readonly,
        filter: true,
        mandatory: true,
        hideDetails: true,
        column: true,
      },
      chips
    );
  },
});
