<template>
  <v-btn
    v-if="!hasErrors"
    :disabled="disabled || readonly"
    class="text-none flex-align"
    size="x-small"
  >
    <v-icon size="15" class="mt-1">visibility</v-icon>
    <span class="ml-1">Preview</span>

    <v-menu activator="parent">
      <v-card class="px-4 py-2">
        <Info class="caption">Summary</Info>
        <div class="heading-2">
          {{ quantity }} {{ u }} would cost {{ currency }}{{ format(total) }}
        </div>
        <div class="mt-2 caption">
          <template v-if="isGraduated && tiers">
            <div v-for="(tier, i) in tiers" :key="i">
              <span v-if="i == 0">
                First {{ tier.max }} x {{ currency }}{{ tier.unit_amount }}
                <span v-if="tier.flat_amount"
                  >+ {{ currency }}{{ tier.flat_amount }}</span
                >
                = {{ currency
                }}{{
                  format(
                    Number(tier.max) * (tier.unit_amount || 0) +
                      (tier.flat_amount || 0)
                  )
                }}
              </span>
              <span v-else-if="i == tiers.length - 1">
                Next 1 x {{ currency }}{{ tier.unit_amount }}
                <span v-if="tier.flat_amount"
                  >+ {{ currency }}{{ tier.flat_amount }}</span
                >
                = {{ currency
                }}{{
                  format((tier.unit_amount || 0) + (tier.flat_amount || 0))
                }}
              </span>
              <span v-else>
                Next {{ getFirst(i + 1) - getFirst(i) }} x {{ currency
                }}{{ tier.unit_amount }}
                <span v-if="tier.flat_amount"
                  >+ {{ currency }}{{ tier.flat_amount || 0 }}</span
                >
                = {{ currency
                }}{{
                  format(
                    (getFirst(i + 1) - getFirst(i)) * (tier.unit_amount || 0) +
                      (tier.flat_amount || 0)
                  )
                }}
              </span>
            </div>
          </template>
          <template v-else-if="isVolume">
            {{ quantity }} x {{ currency }}{{ lastTier.unit_amount }}
            <span v-if="lastTier.flat_amount"
              >+ {{ currency }}{{ lastTier.flat_amount }}</span
            >
            = {{ currency
            }}{{
              format(
                quantity * (lastTier.unit_amount || 0) +
                  (lastTier.flat_amount || 0)
              )
            }}
          </template>
        </div>
      </v-card>
    </v-menu>
  </v-btn>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { get, isEmpty } from "lodash";
import type { PriceTier } from "@moirei/complex-pricing/build/types";
import { PricingModel } from "../types";
import Pricing from "@moirei/complex-pricing";

export default defineComponent({
  props: {
    disabled: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    currency: { type: String, required: true },
    model: { type: String as PropType<PricingModel>, required: true },
    errors: { type: [String, Array] as PropType<string | string[]> },
    tiers: { type: Array as PropType<PriceTier[]> },
    increment: { type: Number, default: 1 },
    qtyUnit: { type: String, required: true },
    qtyUnits: { type: String, required: true },
  },
  methods: {
    getFirst(i: number) {
      if (i > 0) {
        return Number(get(this.tiers, `[${i - 1}].max`, 0)) + this.increment;
      }
      return 0;
    },
    format(n: number) {
      return n.toFixed(2);
    },
  },
  computed: {
    isGraduated() {
      return this.model == PricingModel.GRADUATED;
    },
    isVolume() {
      return this.model == PricingModel.VOLUME;
    },
    total() {
      const pricing = new Pricing({
        model: this.isGraduated ? PricingModel.GRADUATED : PricingModel.VOLUME,
        tiers: this.tiers,
      });
      return pricing.price(this.quantity);
    },
    lastTier() {
      const i = this.tiers!.length > 1 ? this.tiers!.length - 1 : 0;
      return this.tiers![i] as PriceTier;
    },
    u() {
      return this.quantity > 1 ? this.qtyUnits : this.qtyUnit;
    },
    quantity() {
      return this.tiers?.length ? this.getFirst(this.tiers.length - 1) : 1;
    },
    hasErrors() {
      return this.errors && !isEmpty(this.errors);
    },
  },
});
</script>
