import { VSelect } from "vuetify/components";
import { getUnitTypes, props } from "./common";

export default defineComponent({
  props,
  render() {
    return h(VSelect, {
      ...this.$attrs,
      class: "complex-pricing complex-pricing__select",
      readonly: this.readonly,
      disabled: this.disabled,
      density: this.density,
      items: getUnitTypes(this.unitTypes),
      modelValue: this.modelValue,
      hideDetails: true,
    });
  },
});
