import { VTextField } from "vuetify/components";
import Standard from "./Standard";
import { props, rules } from "./common";

export default defineComponent({
  props,
  render() {
    const fieldName = "Pricing units";

    return h(
      "div",
      {
        class: "complex-pricing-input complex-pricing-input__package",
      },
      [
        h(Standard, {
          class: "complex-pricing-input__package-amount",
          hideDetails: this.hideDetails,
          readonly: this.readonly,
          disabled: this.disabled,
          textFieldVariant: this.textFieldVariant,
          density: this.density,
          max: this.max,
          min: this.min,
          persistentPlaceholder: this.persistentPlaceholder,
          errorMessages: this.errorMessages,
          unitAmount: this.unitAmount,
          "onUpdate:unitAmount": (v: string | number) =>
            this.$emit("update:unitAmount", Number(v)),
        }),
        h(VTextField, {
          class: "complex-pricing-input__package-units",
          hideDetails: this.hideDetails,
          readonly: this.readonly,
          disabled: this.disabled,
          variant: this.textFieldVariant,
          density: this.density,
          max: this.maxUnits,
          min: this.minUnits,
          persistentPlaceholder: this.persistentPlaceholder,
          suffix: this.qtyUnits,
          rules: [
            rules.required(fieldName),
            rules.min(fieldName, this.minUnits),
            rules.max(fieldName, this.maxUnits),
          ],
          type: "number",
          prefix: "per",
          placeholder: "10",

          modelValue: this.units,
          "onUpdate:modelValue": (v: string | number) =>
            this.$emit("update:units", Number(v)),
        }),
      ]
    );
  },
});
