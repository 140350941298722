import type { ModelType, PriceTier } from "@moirei/complex-pricing/build/types";
import { isNil, isNumber, isString, isUndefined } from "lodash";
import type { PropType } from "vue";
import type { ErrorMessageType, ErrorStateType } from "../types";

type Rule = (input: any) => string | boolean;

export const props = {
  // pricing data
  model: { type: String as PropType<ModelType> },
  tiers: { type: Array as PropType<PriceTier[]> },
  unitAmount: { type: [String, Number] as PropType<string | number> },
  units: { type: [String, Number] as PropType<string | number> },
  data: { type: Object as PropType<any> },

  // value
  min: { type: Number, required: true },
  max: { type: Number },
  minUnits: { type: Number, required: true },
  maxUnits: { type: Number },
  currency: { type: String, required: true },
  qtyUnit: { type: String },
  qtyUnits: { type: String },
  increment: { type: Number, required: true },

  // component/state
  readonly: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  textFieldVariant: { type: String as PropType<any> },
  density: { type: String as PropType<any> },
  hideDetails: { type: Boolean, default: false },
  persistentPlaceholder: { type: Boolean, default: false },
  errorMessages: { type: [String, Array] as PropType<string | string[]> },
};

const makeArgRule = <T>(fn: {
  (name: string, input: any, a: T): string | boolean;
}) => {
  return (name: string, a: T) => {
    return (input: any) => fn(name, input, a);
  };
};

export const rules = {
  min: makeArgRule<number | undefined>(function (name, input, min) {
    if (isNumber(min) && input < min) {
      return `${name} must not be lesser than ${min}`;
    }
    return true;
  }),
  max: makeArgRule<number | undefined>(function (name, input, max) {
    if (isNumber(max) && input > max) {
      return `${name} must not be greater than ${max}`;
    }
    return true;
  }),
  lesser: makeArgRule<number | undefined>(function (name, input, max) {
    if (isNumber(max) && input >= max) {
      return `${name} must be lesser than ${max}`;
    }
    return true;
  }),
  greater: makeArgRule<number | undefined>(function (name, input, max) {
    if (isNumber(max) && input <= max) {
      return `${name} must be greater than ${max}`;
    }
    return true;
  }),
  required: (name: string) => {
    return (input: any) => {
      if (isUndefined(input)) {
        return `${name} is required`;
      }
      return true;
    };
  },
};

const pipe = <T extends number | undefined>(
  v: T,
  rules: (Rule | undefined)[],
  cb: { (msg: string): void }
) => {
  for (const rule of rules) {
    if (rule) {
      const check = rule(v);
      if (isString(check)) {
        cb(check);
        return;
      }
    }
  }
};

export const getTierTableItemState = (item: {
  index: number;
  isFirst: boolean;
  isLast: boolean;
  min: number;
  tier: PriceTier;
  tiers: PriceTier[];
}) => {
  const errors: ErrorStateType = {};
  const errorMessages: ErrorMessageType = {};

  if (!item.isFirst && !item.isLast) {
    pipe(
      item.min,
      [
        rules.required("Min range"),
        isNumeric(item.tier.max)
          ? rules.lesser("Min range", Number(item.tier.max))
          : undefined,
        rules.min("Min range", 0),
      ],
      (message) => {
        errors.min = true;
        errorMessages.min = message;
      }
    );

    pipe(
      Number(item.tier.max),
      [
        rules.required("Max range"),
        rules.greater("Max range", item.min),
        rules.min("Max range", 0),
      ],
      (message) => {
        errors.max = true;
        errorMessages.max = message;
      }
    );
  } else if (item.isFirst) {
    pipe(Number(item.tier.max), [rules.min("Max range", 0)], (message) => {
      errors.max = true;
      errorMessages.max = message;
    });
  } else if (item.isLast) {
    pipe(item.min, [rules.min("Min range", 0)], (message) => {
      errors.min = true;
      errorMessages.min = message;
    });
  }

  pipe(
    item.tier.unit_amount,
    [rules.required("Unit amount"), rules.min("Unit amount", 0)],
    (message) => {
      errors.unitAmount = true;
      errorMessages.unitAmount = message;
    }
  );

  pipe(item.tier.flat_amount, [rules.min("Flat amount", 0)], (message) => {
    errors.flatAmount = true;
    errorMessages.flatAmount = message;
  });

  return {
    errors,
    errorMessages,
  };
};
