import type { PricingData } from "@moirei/complex-pricing/build/types";
import {
  type CustomUnitType,
  type DisplayModelType,
} from "../private/complex-pricing/types";
import {
  checkMeasurementType,
  getPricingDefaults,
  isTiersModel,
  modelTypeFromInput,
  unitSelectCmp,
} from "../private/complex-pricing/utils";
import { VSheet } from "vuetify/components";
import { inputCmp, modelSelectCmp } from "../private/complex-pricing/utils";
import "../private/complex-pricing/style.scss";
import { PricingMeasurementUnit } from "../models";
import { get } from "lodash";

export default defineComponent({
  name: "ComplexPricing",
  emits: ["update:modelValue"],
  props: {
    modelValue: { type: Object as PropType<PricingData> },
    tag: { type: String },

    density: { type: String as PropType<any> },
    textFieldVariant: { type: String as PropType<any> },
    rounded: { type: [Number, String] },

    // inputs
    model: { type: String as PropType<DisplayModelType>, default: "text" },
    increment: { type: [Number, String] },
    unit: { type: String },
    units: { type: String },
    currency: { type: String, default: "$" },
    max: { type: [Number, String] },
    min: { type: [Number, String] },
    minUnits: { type: [Number, String] },
    maxUnits: { type: [Number, String] },
    selectUnitType: { type: Boolean, default: false },
    unitTypes: { type: Array as PropType<CustomUnitType[]> },
    measurementDataField: { type: String, default: "measurementType" },

    // status
    readonly: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    mandatory: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    hideDetails: { type: Boolean, default: false },
    persistentPlaceholder: { type: Boolean, default: true },
    errorMessages: { type: [String, Array] as PropType<string | string[]> },

    // sheet props
    elevation: { type: [Number, String] },
    width: { type: [Number, String], default: "auto" },
    height: { type: [Number, String] },
    maxWidth: { type: [Number, String] },
    maxHeight: { type: [Number, String] },
  },
  setup(props, ctx) {
    const getValue = () => {
      const pricing = {
        ...getPricingDefaults(props.model, props.measurementDataField),
        ...props.modelValue,
      };
      const fixedModel = modelTypeFromInput(props.model);
      if (fixedModel) {
        pricing.model = fixedModel;
      }

      return pricing;
    };

    const pricing = computed({
      set: (p: PricingData) => ctx.emit("update:modelValue", p),
      get: getValue,
    });

    const fieldUpdater = <F extends keyof PricingData>(field: F) => {
      return (v: PricingData[F]) => {
        pricing.value = {
          ...getValue(),
          [field]: v,
        };
      };
    };

    const setMandatoryDefault = once(() => {
      if (props.mandatory && !props.modelValue) {
        pricing.value = getPricingDefaults(props.model);
      }
    });

    // onMounted(setMandatoryDefault);

    const qtyUnit = computed(() => {
      if (props.unit) return props.unit;
      if (
        checkMeasurementType(
          pricing.value,
          PricingMeasurementUnit.WEIGHT,
          props.measurementDataField
        )
      ) {
        return "kg";
      }
      if (
        checkMeasurementType(
          pricing.value,
          PricingMeasurementUnit.VOLUME,
          props.measurementDataField
        )
      ) {
        return "cm&#178;";
      }
      if (
        checkMeasurementType(
          pricing.value,
          PricingMeasurementUnit.QUANTITY,
          props.measurementDataField
        )
      ) {
        return "unit";
      }
      return get(pricing.value?.data, props.measurementDataField) || "unit";
    });

    const qtyUnits = computed(() => {
      if (props.units) return props.units;
      const plural =
        (pricing.value.units || 0) > 1 || isTiersModel(pricing.value.model);
      return (
        qtyUnit.value +
        (plural &&
        !checkMeasurementType(
          pricing.value,
          PricingMeasurementUnit.VOLUME,
          props.measurementDataField
        )
          ? "s"
          : "")
      );
    });

    return {
      pricing,
      qtyUnit,
      qtyUnits,
      fieldUpdater,
    };
  },
  methods: {
    getNodes() {
      const nodes: any = [];

      const modelSelect = modelSelectCmp(this.model);
      const unitSelect =
        this.selectUnitType || this.unitTypes
          ? unitSelectCmp(this.model)
          : undefined;

      let modelCmp: any, unitCmp: any;

      if (modelSelect) {
        modelCmp = h(modelSelect, {
          class: !unitSelect ? "mb-1" : undefined,
          disabled: this.disabled || this.loading,
          readonly: this.readonly,
          density: this.density,
          textFieldVariant: this.textFieldVariant,
          rounded: this.rounded,
          modelValue: this.pricing.model,
          "onUpdate:modelValue": this.fieldUpdater("model"),
        });
      }
      if (unitSelect) {
        const dataUpdater = this.fieldUpdater("data");

        unitCmp = h(unitSelect, {
          class: !modelSelect ? "mb-1" : undefined,
          disabled: this.disabled || this.loading,
          readonly: this.readonly,
          density: this.density,
          textFieldVariant: this.textFieldVariant,
          rounded: this.rounded,
          unitTypes: this.unitTypes,
          modelValue: get(this.pricing.data, this.measurementDataField),
          "onUpdate:modelValue": (v: string) => {
            dataUpdater({
              ...this.pricing.data,
              [this.measurementDataField]: v,
            });
          },
        });
      }

      if (modelSelect && unitSelect) {
        const props: any = {};
        if (this.model === "text") {
          props.class = "complex-pricing__text-group";
        } else if (this.model === "chip") {
          props.class = "mb-1";
        }
        const n = h("div", props, [modelCmp, unitCmp]);
        nodes.push(n);
      } else if (modelSelect) {
        nodes.push(modelCmp);
      } else if (unitSelect) {
        nodes.push(unitCmp);
      }

      const input = h(inputCmp(this.pricing.model!, this.model), {
        model: this.pricing.model,
        tiers: this.pricing.tiers,
        unitAmount: this.pricing.unit_amount,
        units: this.pricing.units,
        data: this.pricing.data,
        disabled: this.disabled || this.loading,
        readonly: this.readonly,
        density: this.density,
        variant: this.textFieldVariant,
        rounded: this.rounded,
        max: this.max ? Number(this.max) : undefined,
        min: Number(this.min || 0),
        maxUnits: this.maxUnits ? Number(this.maxUnits) : undefined,
        minUnits: Number(this.minUnits || 1),
        increment: Number(this.increment || 1),
        hideDetails: this.hideDetails,
        persistentPlaceholder: this.persistentPlaceholder,
        qtyUnit: this.qtyUnit,
        qtyUnits: this.qtyUnits,
        currency: this.currency,
        "onUpdate:model": this.fieldUpdater("model"),
        "onUpdate:tiers": this.fieldUpdater("tiers"),
        "onUpdate:unitAmount": this.fieldUpdater("unit_amount"),
        "onUpdate:units": this.fieldUpdater("units"),
        "onUpdate:data": this.fieldUpdater("data"),
      });
      nodes.push(input);

      return nodes;
    },
  },
  render() {
    const root: any = this.tag || VSheet;
    const props: any = {
      class: [
        "complex-pricing",
        {
          "complex-pricing--fit-width": !this.width,
        },
      ],
    };

    if (!this.tag) {
      // apply v-sheet props
      Object.assign(props, {
        elevation: this.elevation,
        width: this.width,
        height: this.height,
        maxWidth: this.maxWidth,
        maxHeight: this.maxHeight,
        rounded: this.rounded,
      });
    }

    return h(root, props, () => {
      const { before, after } = this.$slots;
      const nodes = this.getNodes();

      if (before) {
        // @ts-ignore
        nodes.unshift(before());
      }
      if (after) {
        // @ts-ignore
        nodes.push(after());
      }

      return nodes;
    });
  },
});
