<template>
  <div class="complex-pricing complex-pricing__text">
    <v-btn
      :readonly="readonly"
      :disabled="disabled"
      size="x-small"
      class="text-none"
    >
      {{ activeTitle }}
      <v-icon size="20">expand_more</v-icon>

      <v-menu activator="parent">
        <v-list density="compact">
          <v-list-item
            v-for="(item, i) in items"
            @click="select(item.value)"
            :key="i"
            :value="i"
          >
            <template #prepend>
              <span class="mr-2">
                <v-icon :color="item.active ? 'primary' : undefined">
                  {{
                    item.active
                      ? "radio_button_checked"
                      : "radio_button_unchecked"
                  }}
                </v-icon>
              </span>
            </template>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-btn>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { getUnitTypes, props } from "./common";

export default defineComponent({
  props,
  computed: {
    items() {
      const types = getUnitTypes(this.unitTypes);
      return types.map((item) => ({
        ...item,
        active: this.modelValue == item.value,
      }));
    },
    activeTitle() {
      const types = getUnitTypes(this.unitTypes);
      const item = types.find((item) => item.value == this.modelValue);
      return item ? item.title : "Select unit type";
    },
  },
  methods: {
    select(value: string) {
      this.$emit("update:modelValue", value);
    },
  },
});
</script>
