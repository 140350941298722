import { VTextField } from "vuetify/components";
import { props, rules } from "./common";

export default defineComponent({
  props,
  render() {
    const fieldName = "Unit amount";

    return h(VTextField, {
      class: "complex-pricing-input complex-pricing-input__chips",
      hideDetails: this.hideDetails,
      readonly: this.readonly,
      disabled: this.disabled,
      variant: this.textFieldVariant,
      density: this.density,
      prefix: this.currency,
      max: this.max,
      min: this.min,
      persistentPlaceholder: this.persistentPlaceholder,
      errorMessages: this.errorMessages,
      placeholder: "0.0",
      type: "number",
      rules: [
        // rules.required(fieldName),
        rules.min(fieldName, this.min),
        rules.max(fieldName, this.max),
      ],

      modelValue: this.unitAmount,
      "onUpdate:modelValue": (v) => this.$emit("update:unitAmount", Number(v)),
    });
  },
});
