<template>
  <div
    class="complex-pricing-input complex-pricing-input__tiers"
    :class="[`complex-pricing-input__${model}`]"
  >
    <v-card variant="flat" border>
      <v-data-table
        class="pricing-table"
        :headers="headers"
        :items="items"
        density="compact"
      >
        <template #item.before="{ index }">
          <span class="prefix">
            {{ index ? "For the next" : "For the first" }}
          </span>
        </template>
        <template #item.after="{ index, item }">
          <v-btn
            v-if="!item.isFirst && !item.isLast && !readonly && !disabled"
            @click="remove(index)"
            class="mr-2"
            size="30"
            icon
          >
            <v-icon>close</v-icon>
          </v-btn>
        </template>
        <template #item.first="{ index, item }">
          <v-text-field
            :disabled="disabled"
            :readonly="readonly || item.isFirst"
            :error="item.errors.min"
            :title="item.errorMessages.min"
            :model-value="item.min"
            @update:model-value="(v) => updateMin(v, index)"
            class="v-text-field__flat max-w-100px min-w-80px"
            density="compact"
            variant="outlined"
            type="number"
            placeholder="0"
            rounded="lg"
            hide-details
          />
        </template>
        <template #item.last="{ index, item }">
          <v-text-field
            :disabled="disabled"
            :readonly="readonly || item.isLast"
            :error="item.errors.max"
            :title="item.errorMessages.max"
            :model-value="item.isLast ? '∞' : item.max"
            @update:model-value="(v) => updateMax(v, index)"
            class="v-text-field__flat max-w-100px min-w-80px"
            density="compact"
            variant="outlined"
            :type="item.isLast ? 'text' : 'number'"
            placeholder="0"
            rounded="lg"
            hide-details
          />
        </template>
        <template #item.units="{ index, item }">
          <v-text-field
            :disabled="disabled"
            :readonly="readonly"
            :persistent-placeholder="persistentPlaceholder"
            :prefix="currency"
            :min="min"
            :step="increment"
            :error="item.errors.unitAmount"
            :title="item.errorMessages.unitAmount"
            :model-value="item.unit_amount"
            @update:model-value="(v) => updateUnitAmount(v, index)"
            class="v-text-field__flat max-w-100px min-w-80px"
            density="compact"
            variant="outlined"
            type="number"
            placeholder="0.0"
            rounded="lg"
            hide-details
          />
        </template>
        <template #item.flat="{ index, item }">
          <v-text-field
            :disabled="disabled"
            :readonly="readonly"
            :persistent-placeholder="persistentPlaceholder"
            :prefix="currency"
            :min="minUnits"
            :error="item.errors.flatAmount"
            :title="item.errorMessages.flatAmount"
            :model-value="item.flat_amount"
            @update:model-value="(v) => updateFlatAmount(v, index)"
            class="v-text-field__flat max-w-100px min-w-80px"
            density="compact"
            variant="outlined"
            type="number"
            placeholder="0.0"
            rounded="lg"
            hide-details
            persistent-hint
          />
        </template>
        <template #bottom> </template>
      </v-data-table>
    </v-card>
    <div class="flex-align py-1">
      <TiersInputError v-if="!hideDetails" :errors="errors" />
      <v-spacer />
      <TiersInputPreview
        :model="model"
        :tiers="tiers"
        :currency="currency"
        :disabled="disabled"
        :readonly="readonly"
        :errors="errors"
        :increment="increment"
        :qty-unit="qtyUnit"
        :qty-units="qtyUnits"
      />
      <v-btn
        @click="add()"
        :disabled="disabled || readonly"
        class="text-none"
        size="x-small"
      >
        Add another tier
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { getTierTableItemState, props } from "./common";
import { PriceCalculator } from "@moirei/complex-pricing";
import TiersInputError from "./TiersInputError.vue";
import TiersInputPreview from "./TiersInputPreview.vue";
import type { PriceTier } from "@moirei/complex-pricing/build/types";
import type { TierTableItem } from "../types";
import { first } from "lodash";

export default defineComponent({
  props,
  components: { TiersInputError, TiersInputPreview },
  setup(props, ctx) {
    const items = computed(() => {
      const tiers = props.tiers || [];
      return tiers.map((tier, i): TierTableItem => {
        const isFirst = i == 0;
        const isLast = i == tiers.length - 1;
        const min = Number(tiers[i - 1]?.max || 0);

        const { errors, errorMessages } = getTierTableItemState({
          index: i,
          isFirst,
          isLast,
          min,
          tier,
          tiers,
        });

        return {
          ...tier,
          index: i,
          isFirst,
          isLast,
          min,
          errors,
          errorMessages,
        };
      });
    });

    const update = (tiers: PriceTier[]) => {
      ctx.emit("update:tiers", tiers);
    };

    const add = (tier?: PriceTier) => {
      if (tier) {
        const tiers = [...(props.tiers || [])];
        if (tiers.length > 1) {
          tiers.splice(tiers.length - 1, 0, tier); // insert right after the last
        } else {
          tiers.push(tier);
        }
        update(tiers);
      } else {
        add({
          max: 0,
          unit_amount: undefined,
          flat_amount: undefined,
        });
      }
    };

    const remove = (index: number) => {
      const tiers = [...(props.tiers || [])];
      tiers.splice(index, 1);
      update(tiers);
    };

    const updateAt = (
      offset: number,
      fn: {
        (v: number, tier: PriceTier): void;
      }
    ) => {
      return (v: string | number, i: number) => {
        const tiers = [...(props.tiers || [])];
        const prev = tiers[i + offset];
        if (prev) {
          fn(Number(v), prev);
          update(tiers);
        }
      };
    };

    const updateMin = updateAt(-1, (v, prev) => {
      prev.max = v;
    });

    const updateMax = updateAt(0, (v, curr) => {
      curr.max = v;
    });

    const updateUnitAmount = updateAt(0, (v, curr) => {
      curr.unit_amount = v;
    });

    const updateFlatAmount = updateAt(0, (v, curr) => {
      curr.flat_amount = v;
    });

    onMounted(() => {
      if (!props.tiers?.length) {
        update([
          {
            max: 1,
            unit_amount: undefined,
            flat_amount: undefined,
          },
          {
            max: PriceCalculator.INFINITY,
            unit_amount: undefined,
            flat_amount: undefined,
          },
        ]);
      }
    });

    return {
      items,
      add,
      remove,
      updateMin,
      updateMax,
      updateUnitAmount,
      updateFlatAmount,
    };
  },
  computed: {
    headers() {
      return [
        // { title: "Tiers", align: "start", sortable: false, key: "tiers" },
        { title: "", align: "start", sortable: false, key: "before" },
        {
          title: `First ${this.qtyUnit}`,
          align: "center",
          sortable: false,
          key: "first",
        },
        {
          title: `Last ${this.qtyUnit}`,
          align: "center",
          sortable: false,
          key: "last",
        },
        {
          title: `Per ${this.qtyUnit}`,
          align: "center",
          sortable: false,
          key: "units",
        },
        { title: "Flat fee", align: "center", sortable: false, key: "flat" },
        { title: "", align: "end", sortable: false, key: "after" },
      ];
    },
    errors() {
      for (const item of this.items) {
        const hasError = someTrue(Object.values(item.errors));
        if (hasError) {
          return (
            first(Object.values(item.errorMessages)) +
            ` at row ${item.index + 1}`
          );
        }
      }
      return this.errorMessages;
    },
  },
});
</script>

<style lang="scss">
.complex-pricing-input__tiers {
  .pricing-table {
    width: 100%;
  }
  .pricing-table thead,
  .pricing-table tbody {
    tr:hover {
      background-color: transparent !important;
    }
  }
  .v-chip--disabled {
    opacity: inherit;
  }

  tbody .v-data-table__td:first-child {
    border-right: thin solid
      rgba(var(--v-border-color), var(--v-border-opacity));
    @apply highlight lt-md:min-w-120px lt-md:text-3.2;
  }

  tbody .v-data-table__td:not(:first-child) {
    @apply pa-1;
  }
}
</style>
