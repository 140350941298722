import { isString } from "lodash";
import { PricingMeasurementUnit, type CustomUnitType } from "../types";
import type { PropType } from "vue";

export const unitTypes = [
  { title: "Quanity (units)", value: PricingMeasurementUnit.QUANTITY },
  { title: "Weight (kg)", value: PricingMeasurementUnit.WEIGHT },
];

export const props = {
  modelValue: { type: String as PropType<string>, required: true },
  readonly: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  density: { type: String as PropType<any> },
  unitTypes: { type: Array as PropType<CustomUnitType[]> },
};

export const getUnitTypes = (types?: CustomUnitType[]) => {
  if (types) {
    return types.map((type) =>
      isString(type) ? { title: type, value: type } : type
    );
  }

  return unitTypes;
};
