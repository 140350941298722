import type { PriceTier } from "@moirei/complex-pricing/build/types";

export type DisplayModelType =
  | "text"
  | "chip"
  | "select"
  | "standard"
  | "package"
  | "graduated"
  | "volume";

export type PricingModelLike =
  | PricingModel
  | "STANDARD"
  | "PACKAGE"
  | "GRADUATED"
  | "VOLUME"
  | "standard"
  | "package"
  | "graduated"
  | "volume";

export enum PricingModel {
  STANDARD = "standard",
  PACKAGE = "package",
  GRADUATED = "graduated",
  VOLUME = "volume",
}

export enum PricingMeasurementUnit {
  QUANTITY = "QUANTITY",
  WEIGHT = "WEIGHT",
  VOLUME = "VOLUME",
}

export type ErrorStateType = Partial<{
  min: boolean;
  max: boolean;
  unitAmount: boolean;
  flatAmount: boolean;
}>;

export type ErrorMessageType = Partial<{
  min: string;
  max: string;
  unitAmount: string;
  flatAmount: string;
}>;

export type TierTableItem = PriceTier & {
  index: number;
  isFirst: boolean;
  isLast: boolean;
  min: number;
  errors: ErrorStateType;
  errorMessages: ErrorMessageType;
};

export type CustomUnitType =
  | string
  | {
      title: string;
      value: string;
    };
